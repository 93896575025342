<template>
  <div class="page__wrapper">
    <!-- 整表信息 -->
    <div class="top-desc">
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerCode') }}{{ headInfo.dealer && headInfo.dealer.code }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ $t('dealer_finance.operatingData.reporter') }}{{ headInfo.compiler }}
        </el-col>
      </el-row>
      <el-row style="padding:6px 0;">
        <el-col :span="12">
          {{ $t('dealer_finance.operatingData.dealerName') }}{{ headInfo.dealer && headInfo.dealer.name }}
        </el-col>
        <el-col
          :span="12"
          style="text-align:right;"
        >
          {{ date1 }}
        </el-col>
      </el-row>
    </div>

    <!-- 表 2-1 人员信息表 -->
    <div class="table-title">
      {{ $t('dealer_finance.operatingData.personnelInformationTable') }}
    </div>
    <el-row class="simple-table">
      <el-col
        :span="4"
        style="font-weight:600;text-align:right;padding: 0 4px;"
        class="table-cell"
      >
        {{ tableData21[0].name }}
      </el-col>
      <el-col
        :span="4"
        style="border-left: none;padding: 0 4px;"
        class="table-cell"
      >
        {{ $utils.numberFormat(totalStuffNumber, { thousand: true }) }}
      </el-col>
    </el-row>

    <!-- 表 2-1-1 车辆销售部门人员信息表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.vehicleSaleDepPersonInfo') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('211')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid211"
      style="width:100%;height:278px;"
      :column-defs="columnDefs211"
      :row-data.sync="tableData211"
      :grid-options="gridOptions211"
    />

    <!-- 表 2-1-2 售后部门人员信息表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.afterSalesDepPersonInfo') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('212')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid212"
      style="width:100%;height:278px;"
      :column-defs="columnDefs212"
      :row-data.sync="tableData212"
      :grid-options="gridOptions212"
    />

    <!-- 表 2-1-3 行政管理部门人员信息表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.adminPersonInfo') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('213')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid213"
      style="width:100%;height:138px;"
      :column-defs="columnDefs213"
      :row-data.sync="tableData213"
      :grid-options="gridOptions213"
    />

    <!-- 表 2-2 二手车业务数据表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.usedCarBizData') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('22')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid22"
      style="width:100%;height:194px;"
      :column-defs="columnDefs22"
      :row-data.sync="tableData22"
      :grid-options="gridOptions22"
    />

    <!-- 表 2-3 售后业务统计表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.afterSalesBizStatistics') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('23')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid23"
      style="width:100%;height:108px;"
      :column-defs="columnDefs23"
      :row-data.sync="tableData23"
      :grid-options="gridOptions23"
    />

    <!-- 表 2-4 新车销售返利（VME）-金融贴息 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.newVehicleSalesRebate') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('24')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid24"
      style="width:100%;height:80px;"
      :column-defs="columnDefs24"
      :row-data.sync="tableData24"
      :grid-options="gridOptions24"
    />

    <!-- 表 2-5 场地情况表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.locationTable') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.period') }}{{ date1 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('25')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <el-row class="simple-table">
      <el-col
        :span="4"
        style="font-weight:600;text-align:right;padding: 0 4px;"
        class="table-cell"
      >
        {{ initTableData25[0].name }}
      </el-col>
      <el-col
        :span="4"
        style="border-left: none;"
        class="table-cell"
      >
        <el-select
          v-model="siteFlag"
          size="mini"
          :disabled="!editable"
          @change="onSiteFlagChange"
        >
          <el-option
            v-for="item in yesNO"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-col>
      <el-col
        :span="16"
        style="border-left:none;color:red;padding: 0 4px;font-weight:600;text-align:center;"
        class="table-cell"
      >
        {{ siteFlag === 'Y' ? '下表 2-5-1 场地租赁情况表无需填写' : '请填写表 2-5-1 场地租赁情况表' }}
      </el-col>
    </el-row>

    <!-- 表 2-5-1 场地租赁情况表 -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.siteLeaseTable') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        <el-button
          v-show="editable"
          :disabled="siteFlag==='Y'"
          type="primary"
          @click="handleSaveClick('251')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid251"
      style="width:100%;height:220px;"
      :column-defs="columnDefs251"
      :row-data.sync="tableData251"
      :grid-options="gridOptions251"
    />

    <!-- 表 2-6-1 应收账款余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5ReceiveBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('261')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid261"
      style="width:100%;height:220px;"
      :column-defs="columnDefs261"
      :row-data.sync="tableData261"
      :grid-options="gridOptions261"
    />

    <!-- 表 2-6-2 预付款项余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5AdvanceBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('262')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid262"
      style="width:100%;height:220px;"
      :column-defs="columnDefs262"
      :row-data.sync="tableData262"
      :grid-options="gridOptions262"
    />

    <!-- 表 2-6-3 其他应收款余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5OtherReceiveBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('263')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid263"
      style="width:100%;height:220px;"
      :column-defs="columnDefs263"
      :row-data.sync="tableData263"
      :grid-options="gridOptions263"
    />

    <!-- 表 2-6-4 应付账款余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5PayBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('264')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid264"
      style="width:100%;height:220px;"
      :column-defs="columnDefs264"
      :row-data.sync="tableData264"
      :grid-options="gridOptions264"
    />

    <!-- 表 2-6-5 预收款项余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5AdvanceReceiveBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('265')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid265"
      style="width:100%;height:220px;"
      :column-defs="columnDefs265"
      :row-data.sync="tableData265"
      :grid-options="gridOptions265"
    />

    <!-- 表 2-6-6 其他应付款余额前5名（包含关联方及非关联方） -->
    <el-row>
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.top5OtherPayBalance') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('266')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid266"
      style="width:100%;height:220px;"
      :column-defs="columnDefs266"
      :row-data.sync="tableData266"
      :grid-options="gridOptions266"
    />

    <!-- 表 2-7 售后配件库存库龄统计表 -->
    <el-row style="width:50%;">
      <el-col
        :span="12"
        class="table-title"
      >
        {{ $t('dealer_finance.operatingData.afterSalesPartsInventoryAge') }}
      </el-col>
      <el-col
        :span="12"
        class="table-period"
      >
        {{ $t('dealer_finance.operatingData.time') }}{{ date2 }}
        <el-button
          v-show="editable"
          type="primary"
          @click="handleSaveClick('27')"
        >
          {{ $t('operation.save') }}
        </el-button>
      </el-col>
    </el-row>
    <ag-grid
      ref="grid27"
      style="width:50%;height:164px;"
      :column-defs="columnDefs27"
      :row-data.sync="tableData27"
      :grid-options="gridOptions27"
    />
  </div>
</template>

<script>
import Axios from 'axios'

const BASEURL = {
  update: '/jlr/tt/selfExaminationData/update',
  list1: '/jlr/tt/operatingData1/list',
  update1: '/jlr/tt/operatingData1/batch/update',
  list2: '/jlr/tt/operatingData2/list',
  update2: '/jlr/tt/operatingData2/batch/update',
  list3: '/jlr/tt/operatingData3/list',
  update3: '/jlr/tt/operatingData3/batch/update',
  list4: '/jlr/tt/operatingData4/list',
  update4: '/jlr/tt/operatingData4/batch/update',
  list5: '/jlr/tt/operatingData5/list',
  update5: '/jlr/tt/operatingData5/batch/update',
  list6: '/jlr/tt/operatingData6/list',
  update6: '/jlr/tt/operatingData6/batch/update',
  list7: '/jlr/tt/operatingData7/list',
  update7: '/jlr/tt/operatingData7/batch/update'
}

export default {
  name: 'OperatingData',
  props: {
    editable: { type: Boolean, default: true },
    detailData: { type: Object },
    headInfo: { type: Object }
  },
  data () {
    return {
      gridOptions211: {},
      gridOptions212: {},
      gridOptions213: {},
      gridOptions22: {},
      gridOptions23: {},
      gridOptions24: {},
      gridOptions251: {},
      gridOptions261: {},
      gridOptions262: {},
      gridOptions263: {},
      gridOptions264: {},
      gridOptions265: {},
      gridOptions266: {},
      gridOptions27: {},

      tableData21: [{ name: '店端人员总数' }],

      initTableData211: [
        {
          classifyByDep1: '车辆销售部门',
          classifyByDep2: '新车销售经理'
        },
        { classifyByDep2: '新车销售顾问' },
        { classifyByDep2: '大客户专员' },
        { classifyByDep2: '金融保险经理' },
        { classifyByDep2: '新车部门其他' },
        {
          classifyByDep2: '二手车部门经理'
        },
        { classifyByDep2: '二手车专员' },
        { classifyByDep1: '车辆销售部门总人数' }
      ],
      tableData211: [],
      sum211: 0,
      date211: '',

      initTableData212: [
        {
          classifyByDep1: '售后部门',
          classifyByDep2: '车间技师',
          incomeStmPayrollAcc: `主营业务成本-售后成本 -钣喷维修成本-工时成本
            主营业务成本-售后成本 -机电维修成本-工时成本
            主营业务成本-售后成本 -保修成本-工时成本`
        },
        { classifyByDep2: '车间学徒' },
        { classifyByDep2: '售后经理' },
        { classifyByDep2: '配件经理' },
        { classifyByDep2: '配件顾问' },
        {
          classifyByDep2: '售后服务顾问'
        },
        { classifyByDep2: '售后部门其他' },
        { classifyByDep1: '售后部门总人数' }
      ],
      tableData212: [],
      sum212: 0,
      date212: '',

      initTableData213: [
        {
          classifyByDep1: '行政管理部门',
          classifyByDep2: '总经理办公室',
          incomeStmPayrollAcc: `间接费用-行政部门人员薪酬和福利费-行政及管理类岗位`
        },
        { classifyByDep2: '其他行政管理人员' },
        // { classifyByDep2: '财务部', incomeStmPayrollAcc: '间接费用-行政部门人员薪酬和福利费-财务类岗位' },
        // { classifyByDep2: '市场部', incomeStmPayrollAcc: '间接费用-行政部门人员薪酬和福利费-市场部岗位' },
        // { classifyByDep2: 'CRM部', incomeStmPayrollAcc: '间接费用-行政部门人员薪酬和福利费-CRM部岗位' },
        // {
        //   classifyByDep2: '行政管理其他',
        //   incomeStmPayrollAcc: '间接费用-行政部门人员薪酬和福利费-其他岗位'
        // },
        { classifyByDep1: '行政管理部门总人数' }
      ],
      tableData213: [],
      sum213: 0,
      date213: '',

      initTableData22: [
        {
          category: '二手车销售业务',
          detail: 'APO（认证二手车）',
          incomeStmUsedCarGrossPrfAcc: '水平业务收入-二手车销售毛利-认证二手车毛利'
        },
        {
          detail: '非认证二手车',
          incomeStmUsedCarGrossPrfAcc: '水平业务收入-二手车销售毛利-非认证二手车毛利'
        },
        {
          detail: '小计'
        },
        {
          category: '二手车置换业务',
          detail: '二手车置换新车',
          incomeStmUsedCarGrossPrfAcc: '---'
        },
        {
          category: '试乘试驾车处置',
          detail: '试乘试驾车处置',
          incomeStmUsedCarGrossPrfAcc: '---'
        }
      ],
      tableData22: [],
      date22: '',

      initTableData23: [
        {
          project: '工时数',
          unit: '小时',
          remark: '指经销商当月实际销售工时总数，包括保养工时数、机电维修工时数、钣喷工时数和保修工时数'
        },
        {
          project: '进场台次',
          unit: '辆',
          remark: '第一次结算金额不为零的工单数，且一辆车一天只能有一次进场'
        }
      ],
      tableData23: [],
      date23: '',

      initTableData24: [{ remark: '预估通过VRD/VME的形式发放的厂商贴息' }],
      tableData24: [],
      date24: '',

      initTableData25: [{ name: '是否为自有场地' }],
      tableData25: [],
      siteFlag: 'Y',
      date25: '',

      initTableData251: [{}, {}, {}, {}, {}, { brand: '本月应计租金合计' }],
      tableData251: [],
      date251: '',

      initTableData261: [{}, {}, {}, {}, {}, {}],
      tableData261: [],
      date261: '',

      initTableData262: [{}, {}, {}, {}, {}, {}],
      tableData262: [],
      date262: '',

      initTableData263: [{}, {}, {}, {}, {}, {}],
      tableData263: [],
      date263: '',

      initTableData264: [{}, {}, {}, {}, {}, {}],
      tableData264: [],
      date264: '',

      initTableData265: [{}, {}, {}, {}, {}, {}],
      tableData265: [],
      date265: '',

      initTableData266: [{}, {}, {}, {}, {}, {}],
      tableData266: [],
      date266: '',

      initTableData27: [
        { project: '0-180天' },
        { project: '181-365天' },
        { project: '365天以上' },
        { project: '总计' }
      ],
      tableData27: [],
      date27: ''
    }
  },
  computed: {
    date1 () {
      return this.headInfo.period ? this.$moment(this.headInfo.period, 'YYYYMM').format('YYYY年MM月') : ''
    },
    date2 () {
      return this.headInfo.period ? this.$moment(this.headInfo.period, 'YYYYMM').endOf('month').format('YYYY-MM-DD') : ''
    },
    yesNO () {
      return this.$getDictList('yes_no').map(item => {
        return { value: item.value, label: item.label }
      })
    },
    totalStuffNumber () {
      return this.sum211 + this.sum212 + this.sum213
    },
    columnDefs211 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.classifyByDep'),
          field: 'classifyByDep1',
          rowSpan: params => {
            return params.data.classifyByDep1 === '车辆销售部门' ? 7 : 1
          },
          cellStyle: params => {
            const isA = (params.data.classifyByDep1 === '车辆销售部门')
            const isB = (params.data.classifyByDep1 === '车辆销售部门总人数')
            return isA ? { backgroundColor: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          colSpan: params => {
            return params.data.classifyByDep1 === '车辆销售部门总人数' ? 2 : 1
          },
          minWidth: 120
        },
        {
          headerName: '',
          field: 'classifyByDep2',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.staffNumber'),
          field: 'quantity',
          editable: params => params.node.rowIndex === 7 ? false : this.editable,
          cellEditorParams: {
            rules: { type: 'number', message: this.$t('validate.notNumber') }
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => {
            return this.$utils.numberFormat(params.node.rowIndex === 7 ? this.sum211 : params.data.quantity, { thousand: true })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.incomeStmPayrollAcc'),
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 5 : (params.node.rowIndex === 5 ? 2 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 5
            let style1 = { backgroundColor: 'white', wordWrap: 'break-word', whiteSpace: 'normal' }
            let style2 = Object.assign({}, style1, { borderBottom: '1px solid #e4e7e8' })
            return isA ? style1 : (isB ? style2 : {})
          },
          cellRenderer: params => {
            if (params.node.rowIndex === 0) {
              return `<div>新车部门费用-新车直接变动费用-新车部门人员销售佣金/奖金</div>
                <div>新车部门费用-新车直接固定费用-新车部门薪酬和福利费</div>`
            } else if (params.node.rowIndex === 5) {
              return `<div>二手车业务费用-二手车部门直接变动费用-二手车业务人员销售佣金/奖金</div>
                <div>二手车业务费用-二手车部门直接固定费用-二手车业务人员薪酬和福利费</div>`
            }
          },
          minWidth: 420
        },
        {
          headerName: this.$t('dealer_finance.operatingData.currentAmount'),
          field: 'amount',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 5 : (params.node.rowIndex === 5 ? 2 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 5
            let style1 = { backgroundColor: 'white' }
            let style2 = { backgroundColor: 'white', borderBottom: '1px solid #e4e7e8' }
            return isA ? style1 : (isB ? style2 : {})
          },
          valueFormatter: params => {
            const sum = (this.tableData211[0].amount || 0) + (this.tableData211[5].amount || 0)
            return this.$utils.numberFormat(params.node.rowIndex === 7 ? sum : params.value, { thousand: true, toFixed: 2 })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.perCapitaMonthlySalary'),
          field: 'monthlySalary',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 5 : (params.node.rowIndex === 5 ? 2 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 5
            let style1 = { backgroundColor: 'white' }
            let style2 = { backgroundColor: 'white', borderBottom: '1px solid #e4e7e8' }
            return isA ? style1 : (isB ? style2 : {})
          },
          cellRenderer: params => {
            return (params.node.rowIndex === 7) ? '---' : this.$utils.numberFormat(params.data.monthlySalary, { thousand: true, toFixed: 2 })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.specialCaseNote'),
          field: 'remark',
          editable: this.editable,
          minWidth: 500
        }
      ]
    },
    columnDefs212 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.classifyByDep'),
          field: 'classifyByDep1',
          rowSpan: params => {
            return params.data.classifyByDep1 === '售后部门' ? 7 : 1
          },
          cellStyle: params => {
            const isA = params.data.classifyByDep1 === '售后部门'
            const isB = params.data.classifyByDep1 === '售后部门总人数'
            return isA ? { backgroundColor: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          colSpan: params => {
            return params.data.classifyByDep1 === '售后部门总人数' ? 2 : 1
          },
          minWidth: 120
        },
        {
          headerName: '',
          field: 'classifyByDep2',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.staffNumber'),
          field: 'quantity',
          editable: params => params.node.rowIndex === 7 ? false : this.editable,
          cellEditorParams: {
            rules: { type: 'number', message: this.$t('validate.notNumber') }
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => {
            return this.$utils.numberFormat(params.node.rowIndex === 7 ? this.sum212 : params.data.quantity, { thousand: true })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.incomeStmPayrollAcc'),
          field: 'incomeStmPayrollAcc',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : (params.node.rowIndex === 2 ? 5 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 2
            let style1 = { backgroundColor: 'white', wordWrap: 'break-word', whiteSpace: 'normal' }
            let style2 = Object.assign({}, style1, { borderBottom: '1px solid #e4e7e8' })
            return isA ? style1 : (isB ? style2 : {})
          },
          cellRenderer: params => {
            if (params.node.rowIndex === 0) {
              return `<div>主营业务成本-售后成本-钣喷维修成本-工时成本</div>
                <div>主营业务成本-售后成本 -机电维修成本-工时成本</div>
                <div>主营业务成本-售后成本 -保修成本-工时成本</div>`
            } else if (params.node.rowIndex === 2) {
              return `<div>售后部门费用-售后直接变动费用-售后部门人员销售佣金/奖金</div>
                <div>售后部门费用-售后直接固定费用-售后部门薪酬和福利费</div>`
            }
          },
          minWidth: 380
        },
        {
          headerName: this.$t('dealer_finance.operatingData.currentAmount'),
          field: 'amount',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : (params.node.rowIndex === 2 ? 5 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 2
            let style1 = { backgroundColor: 'white' }
            let style2 = { backgroundColor: 'white', borderBottom: '1px solid #e4e7e8' }
            return isA ? style1 : (isB ? style2 : {})
          },
          valueFormatter: params => {
            const sum = (this.tableData212[0].amount || 0) + (this.tableData212[2].amount || 0)
            return this.$utils.numberFormat(params.node.rowIndex === 7 ? sum : params.value, { thousand: true, toFixed: 2 })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.perCapitaMonthlySalary'),
          field: 'monthlySalary',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : (params.node.rowIndex === 2 ? 5 : 1)
          },
          cellStyle: params => {
            const isA = params.node.rowIndex === 0
            const isB = params.node.rowIndex === 2
            let style1 = { backgroundColor: 'white' }
            let style2 = { backgroundColor: 'white', borderBottom: '1px solid #e4e7e8' }
            return isA ? style1 : (isB ? style2 : {})
          },
          cellRenderer: params => {
            return (params.node.rowIndex === 7) ? '---' : this.$utils.numberFormat(params.data.monthlySalary, { thousand: true, toFixed: 2 })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.specialCaseNote'),
          field: 'remark',
          editable: this.editable,
          minWidth: 500
        }
      ]
    },
    columnDefs213 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.classifyByDep'),
          field: 'classifyByDep1',
          rowSpan: params => {
            return params.data.classifyByDep1 === '行政管理部门' ? 2 : 1
          },
          cellStyle: params => {
            const isA = params.data.classifyByDep1 === '行政管理部门'
            const isB = params.data.classifyByDep1 === '行政管理部门总人数'
            return isA ? { backgroundColor: 'white' } : (isB ? { fontWeight: 600, textAlign: 'right' } : {})
          },
          colSpan: params => {
            return params.data.classifyByDep1 === '行政管理部门总人数' ? 2 : 1
          },
          minWidth: 120
        },
        {
          headerName: '',
          field: 'classifyByDep2',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.staffNumber'),
          field: 'quantity',
          editable: params => params.node.rowIndex === 2 ? false : this.editable,
          cellEditorParams: {
            rules: { type: 'number', message: this.$t('validate.notNumber') }
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => {
            return this.$utils.numberFormat(params.node.rowIndex === 2 ? this.sum213 : params.data.quantity, { thousand: true })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.incomeStmPayrollAcc'),
          field: 'incomeStmPayrollAcc',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : 1
          },
          cellStyle: params => {
            return params.node.rowIndex === 0 ? { backgroundColor: 'white' } : {}
          },
          minWidth: 360
        },
        {
          headerName: this.$t('dealer_finance.operatingData.currentAmount'),
          field: 'amount',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : 1
          },
          cellStyle: params => {
            return params.node.rowIndex === 0 ? { backgroundColor: 'white' } : {}
          },
          valueFormatter: params => {
            const sum = (this.tableData213[0].amount || 0) + (this.tableData213[1].amount || 0)
            return this.$utils.numberFormat(params.node.rowIndex === 2 ? sum : params.value, { thousand: true, toFixed: 2 })
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.perCapitaMonthlySalary'),
          field: 'monthlySalary',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 2 : 1
          },
          cellStyle: params => {
            return params.node.rowIndex === 0 ? { backgroundColor: 'white' } : {}
          },
          cellRenderer: params => {
            return (params.node.rowIndex === 2) ? '---' : this.$utils.numberFormat(params.data.monthlySalary, { thousand: true, toFixed: 2 })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.specialCaseNote'),
          field: 'remark',
          editable: this.editable,
          minWidth: 500
        }
      ]
    },
    columnDefs22 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.category'),
          field: 'category',
          rowSpan: params => {
            return params.data.category === '二手车销售业务' ? 3 : 1
          },
          cellStyle: params => {
            const isA = params.data.category === '二手车销售业务'
            return isA ? { backgroundColor: 'white' } : {}
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.detail'),
          field: 'detail',
          cellStyle: params => {
            const isA = params.data.detail === '小计'
            return isA ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.operatingData.numberTimes'),
          field: 'vehicles',
          editable: params => {
            return params.node.rowIndex === 2 ? false : this.editable
          },
          cellEditorParams: {
            rules: { type: 'number', message: this.$t('validate.notNumber') }
          },
          valueParser: params => Math.abs(parseInt(params.newValue || 0)),
          valueGetter: params => {
            const sum = (this.tableData22[0].vehicles || 0) + (this.tableData22[1].vehicles || 0)
            const value = params.node.rowIndex === 2 ? sum : params.data.vehicles
            return this.$utils.numberFormat(value, { thousand: true })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.incomeStmUsedCarGrossPrfAcc'),
          field: 'incomeStmUsedCarGrossPrfAcc',
          minWidth: 320
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          cellRenderer: params => {
            const isSpecial = (params.node.rowIndex === 3 || params.node.rowIndex === 4)
            return isSpecial ? '---' : this.$utils.numberFormat(params.data.amount, { thousand: true, toFixed: 2 })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.singleIncome'),
          field: 'singleIncome',
          cellRenderer: params => {
            const sum = (this.tableData22[0].vehicles || 0) + (this.tableData22[1].vehicles || 0)
            const av = (!sum || !params.data.amount) ? 0 : this.$utils.accDiv(params.data.amount, sum)
            const value = params.node.rowIndex === 2 ? av : params.data.singleIncome
            const isSpecial = (params.node.rowIndex === 3 || params.node.rowIndex === 4)
            return isSpecial ? '---' : this.$utils.numberFormat(value, { thousand: true, toFixed: 2 })
          },
          tooltipValueGetter: params => {
            const sum = (this.tableData22[0].vehicles || 0) + (this.tableData22[1].vehicles || 0)
            const av = (!sum || !params.data.amount) ? 0 : this.$utils.accDiv(params.data.amount, sum)
            const value = params.node.rowIndex === 2 ? av : params.data.singleIncome
            const isSpecial = (params.node.rowIndex === 3 || params.node.rowIndex === 4)
            return isSpecial ? '' : this.$utils.numberFormat(value, { thousand: true, toFixed: 2 })
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.reportRemark'),
          field: 'reportRemark',
          rowSpan: params => {
            return params.node.rowIndex === 0 ? 3 : 1
          },
          cellStyle: params => {
            return params.node.rowIndex === 0 ? { backgroundColor: 'white' } : {}
          },
          cellRenderer: params => {
            if (params.node.rowIndex === 0) {
              return `<div>金额从损益表引用，无需填报；数量及金额应匹配，不得出现有</div>
              <div>金额无数量，或有数量无金额。</div>`
            } else if (params.node.rowIndex === 3) {
              return '填写当月经销商采购的所有二手车中来自于新车置换业务的数量'
            } else {
              return '填写当月经销商处置试乘试驾车的数量'
            }
          },
          minWidth: 380
        }
      ]
    },
    columnDefs23 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.project'),
          field: 'project',
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.operatingData.unit'),
          field: 'unit',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.amount'),
          field: 'quantity',
          editable: this.editable,
          valueParser: params => {
            const value = params.newValue || 0
            const hours = Math.abs(Number(this.$utils.decimalFormat(value, 2)))
            const vehicles = Math.abs(parseInt(value || 0))
            return params.node.rowIndex === 0 ? hours : vehicles
          },
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.remark'),
          field: 'remark',
          minWidth: 400
        }
      ]
    },
    columnDefs24 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.category'),
          field: 'type',
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.detail'),
          field: 'detail',
          minWidth: 200
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.remark'),
          field: 'remark',
          minWidth: 400
        }
      ]
    },
    columnDefs251 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.brand'),
          field: 'brand',
          editable: params => {
            return (params.node.rowIndex === 5 || this.siteFlag === 'Y') ? false : this.editable
          },
          cellStyle: params => {
            return params.data.brand === '本月应计租金合计' ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          type: 'Select',
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data5_brand').map(item => {
                return { value: item.value, label: item.label }
              })
            }
          },
          valueFormatter: params => {
            const label = this.$getDictLabel({ type: 'tt_operating_data5_brand', value: params.value })
            return params.node.rowIndex === 5 ? params.data.brand : label
          },
          minWidth: 120
        },
        {
          headerName: this.$t('dealer_finance.operatingData.monthlyRent'),
          field: 'monthlyRent',
          editable: params => {
            return (params.node.rowIndex === 5 || this.siteFlag === 'Y') ? false : this.editable
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData251[0].monthlyRent +
            this.tableData251[1].monthlyRent +
            this.tableData251[2].monthlyRent +
            this.tableData251[3].monthlyRent +
            this.tableData251[4].monthlyRent
            return params.node.rowIndex === 5 ? sum : params.data.monthlyRent
          },
          minWidth: 160
        },
        {
          headerName: this.$t('dealer_finance.operatingData.totalLeaseholdArea'),
          field: 'totalLeasedArea',
          editable: params => {
            return (params.node.rowIndex === 5 || this.siteFlag === 'Y') ? false : this.editable
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.monthlyRentPerSqMeter'),
          field: 'rentArea',
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.specialCaseNote2'),
          field: 'remark',
          editable: params => {
            return (params.node.rowIndex === 5 && this.siteFlag === 'Y') ? false : this.editable
          },
          minWidth: 500
        }
      ]
    },
    columnDefs261 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '应收账款前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.receiveBalanceCoName'),
          field: 'accountsReceivableCompany',
          editable: params => {
            return params.node.rowIndex === 5 ? false : this.editable
          },
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: params => {
            return params.node.rowIndex === 5 ? false : this.editable
          },
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData261[0].amount +
            this.tableData261[1].amount +
            this.tableData261[2].amount +
            this.tableData261[3].amount +
            this.tableData261[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: params => {
            return params.node.rowIndex === 5 ? false : this.editable
          },
          minWidth: 280
        }
      ]
    },
    columnDefs262 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '预付款项前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.advanceBalanceCoName'),
          field: 'accountsReceivableCompany',
          editable: this.editable,
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData262[0].amount +
            this.tableData262[1].amount +
            this.tableData262[2].amount +
            this.tableData262[3].amount +
            this.tableData262[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: this.editable,
          minWidth: 280
        }
      ]
    },
    columnDefs263 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '其他应收款前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.otherReceiveBalanceCoName'),
          field: 'accountsReceivableCompany',
          editable: this.editable,
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData263[0].amount +
            this.tableData263[1].amount +
            this.tableData263[2].amount +
            this.tableData263[3].amount +
            this.tableData263[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: this.editable,
          minWidth: 280
        }
      ]
    },
    columnDefs264 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '应付账款前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.PayBalanceCoName'),
          field: 'accountsReceivableCompany',
          editable: this.editable,
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData264[0].amount +
            this.tableData264[1].amount +
            this.tableData264[2].amount +
            this.tableData264[3].amount +
            this.tableData264[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: this.editable,
          minWidth: 280
        }
      ]
    },
    columnDefs265 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '预收款项前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.advanceReceiveCoName'),
          field: 'accountsReceivableCompany',
          editable: this.editable,
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData265[0].amount +
            this.tableData265[1].amount +
            this.tableData265[2].amount +
            this.tableData265[3].amount +
            this.tableData265[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: this.editable,
          minWidth: 280
        }
      ]
    },
    columnDefs266 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.serialNumber'),
          field: 'no',
          cellStyle: params => {
            return params.node.rowIndex === 5 ? { fontWeight: 600, textAlign: 'right' } : {}
          },
          colSpan: params => params.node.rowIndex === 5 ? 3 : 1,
          cellRenderer: params => {
            return params.node.rowIndex === 5 ? '其他应付款前5名合计' : params.data.no
          },
          suppressSizeToFit: true,
          width: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.otherPayBalanceCoName'),
          field: 'accountsReceivableCompany',
          editable: this.editable,
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.relationship'),
          field: 'relation',
          type: 'Select',
          editable: this.editable,
          cellEditorParams: {
            componentProp: {
              optionList: this.$getDictList('tt_operating_data6_relation').map(item => {
                return { value: item.value, label: item.label }
              }),
              clearable: true
            }
          },
          valueFormatter: params => {
            return this.$getDictLabel({ type: 'tt_operating_data6_relation', value: params.value })
          },
          minWidth: 180
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          editable: this.editable,
          valueParser: params => Number(this.$utils.decimalFormat(params.newValue, 2)),
          valueFormatter: params => this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 }),
          valueGetter: params => {
            const sum = this.tableData266[0].amount +
            this.tableData266[1].amount +
            this.tableData266[2].amount +
            this.tableData266[3].amount +
            this.tableData266[4].amount
            return params.node.rowIndex === 5 ? sum : params.data.amount
          },
          minWidth: 100
        },
        {
          headerName: this.$t('dealer_finance.operatingData.useOfProceeds'),
          field: 'moneyUse',
          editable: this.editable,
          minWidth: 280
        }
      ]
    },
    columnDefs27 () {
      return [
        {
          headerName: this.$t('dealer_finance.operatingData.project'),
          field: 'project',
          minWidth: 300
        },
        {
          headerName: this.$t('dealer_finance.operatingData.money'),
          field: 'amount',
          valueParser: params => {
            return params.newValue <= 0 ? 0 : Number(this.$utils.decimalFormat(params.newValue, 2))
          },
          valueFormatter: params => {
            return params.value <= 0 ? 0 : this.$utils.numberFormat(params.value, { thousand: true, toFixed: 2 })
          },
          editable: params => {
            return params.data.project === '配件精品及其他库存金额总计' ? false : this.editable
          },
          valueGetter: params => {
            const sum = (this.tableData27[0].amount || 0) + (this.tableData27[1].amount || 0) + (this.tableData27[2].amount || 0)
            return params.node.rowIndex === 3 ? sum : params.data.amount
          },
          minWidth: 300
        }
      ]
    }
  },
  watch: {
    editable (val) {
      const gridNos = ['211', '212', '213', '22', '23', '24', '251', '261', '262', '263', '264', '265', '266']
      this.$nextTick(() => {
        gridNos.forEach(no => {
          this.$refs[`grid${no}`].redrawGrid(this[`columnDefs${no}`])
        })
      })
    },
    headInfo (val) {
      this.siteFlag = this.headInfo.siteFlag
    }
  },
  beforeMount () {
    this.gridOptions211.suppressRowTransform = true
    this.gridOptions212.suppressRowTransform = true
    this.gridOptions213.suppressRowTransform = true
    this.gridOptions22.suppressRowTransform = true
    this.gridOptions23.suppressRowTransform = true
    this.gridOptions24.suppressRowTransform = true
    this.gridOptions251.suppressRowTransform = true
    this.gridOptions261.suppressRowTransform = true
    this.gridOptions262.suppressRowTransform = true
    this.gridOptions263.suppressRowTransform = true
    this.gridOptions264.suppressRowTransform = true
    this.gridOptions265.suppressRowTransform = true
    this.gridOptions266.suppressRowTransform = true
  },
  methods: {
    handleDataSearch () {
      const loadingFlag = this.$loading({ fullscreen: false, target: this.$el, text: this.$t('tip.systemLoading') })
      const filter = { filter: [{ left: 'head.id', operate: '=', right: this.detailData.id }] }
      Axios.all([
        this.$axios.post(BASEURL.list1, filter),
        this.$axios.post(BASEURL.list2, filter),
        this.$axios.post(BASEURL.list3, filter),
        this.$axios.post(BASEURL.list4, filter),
        this.$axios.post(BASEURL.list5, filter),
        this.$axios.post(BASEURL.list6, filter),
        this.$axios.post(BASEURL.list7, filter)
      ])
        .then(Axios.spread((resp1, resp2, resp3, resp4, resp5, resp6, resp7) => {
          // 表 2-1-1 to 2-1-3
          const data1 = resp1.data.list
          this.sum211 = 0
          this.tableData211 = this.$_.cloneDeep(this.initTableData211)
          const data211 = this.tableData211.map(item => {
            let result = {}
            data1.find(data => {
              if (data.dept === item.classifyByDep2) {
                this.sum211 += (data.quantity || 0)
                result = Object.assign({}, data, item)
              }
            })
            return result
          })
          data211[7] = this.tableData211[7]
          this.$set(this, 'tableData211', data211)

          this.tableData212 = this.$_.cloneDeep(this.initTableData212)
          this.sum212 = 0
          const data212 = this.tableData212.map(item => {
            let result = {}
            data1.find(data => {
              if (data.dept === item.classifyByDep2) {
                this.sum212 += (data.quantity || 0)
                result = Object.assign({}, data, item)
              }
            })
            return result
          })
          data212[7] = this.tableData212[7]
          this.$set(this, 'tableData212', data212)

          this.tableData213 = this.$_.cloneDeep(this.initTableData213)
          this.sum213 = 0
          const data213 = this.tableData213.map(item => {
            let result = {}
            data1.find(data => {
              if (data.dept === item.classifyByDep2) {
                this.sum213 += (data.quantity || 0)
                result = Object.assign({}, data, item)
              }
            })
            return result
          })
          data213[2] = this.tableData213[2]
          this.$set(this, 'tableData213', data213)

          // 表 2-2
          this.tableData22 = this.$_.cloneDeep(this.initTableData22)
          const data2 = resp2.data.list
          const data22 = this.tableData22.map(item => {
            let result = {}
            data2.find(data => {
              if (data.detail === item.detail) {
                result = Object.assign({}, data, item)
              }
            })
            return result
          })
          this.$set(this, 'tableData22', data22)

          // 表 2-3
          this.tableData23 = this.$_.cloneDeep(this.initTableData23)
          const data3 = resp3.data.list
          const data23 = this.tableData23.map(item => {
            let result = {}
            data3.find(data => {
              if (data.unit === item.unit) {
                result = Object.assign({}, data, item)
              }
            })
            return result
          })
          this.$set(this, 'tableData23', data23)

          // 表 2-4
          this.tableData24 = this.$_.cloneDeep(this.initTableData24)
          const data4 = resp4.data.list
          if (data4 && data4[0]) {
            const data24 = Object.assign({}, data4[0], this.tableData24[0])
            this.date24 = data4[0] && data4[0].updateDate ? this.$moment(data4[0].updateDate).format('YYYY年MM月') : ''
            this.$set(this.tableData24, 0, data24)
          }

          // 表 2-5 2-5-1
          this.tableData251 = this.$_.cloneDeep(this.initTableData251)
          const data5 = resp5.data.list
          for (let i in data5) {
            this.$set(this.tableData251, i, data5[i])
          }

          // 表 2-6-1 to 2-6-6
          const data6 = resp6.data.list
          // 统一处理表 2-6-1 to 2-6-6 数据
          const deal6 = (resp, num) => {
            this['tableData26' + num] = this.$_.cloneDeep(this['initTableData26' + num])
            let dataRs = resp.filter(data => { return data.type === `data6_type_${num}` })
            dataRs = this.$_.sortBy(dataRs, item => { return item.no })
            for (let i in dataRs) { this.$set(this[`tableData26${num}`], i, dataRs[i]) }
          }
          deal6(data6, 1)
          deal6(data6, 2)
          deal6(data6, 3)
          deal6(data6, 4)
          deal6(data6, 5)
          deal6(data6, 6)

          // 表 2-7
          const data7 = this.$_.cloneDeep(resp7.data.list)
          this.tableData27 = this.$_.cloneDeep(this.initTableData27)
          for (let i in this.initTableData27) {
            this.tableData27[i] = data7.find(ele => {
              return ele.project.replace(/\s+/g, '').indexOf(this.tableData27[i].project) > -1
            })
          }
          console.log(this.tableData27)
        })).finally(() => {
          loadingFlag.close()
        })
    },
    onSiteFlagChange (val) {
      this.$refs.grid251.redrawGrid(this.columnDefs251)
    },
    handleDeleteClick () {
      this.$msgbox({
        title: this.$t('tip.warning'),
        message: this.$t('tip.confirmDelete'),
        type: 'warning',
        showCancelButton: true,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.$message({ type: 'success', message: '删除成功（模拟）' })
            done()
          } else done()
        }
      })
        .then(() => {
          this.handleDataSearch()
        })
    },
    handleSaveClick (no) {
      // 特殊处理 2-5，为主表保存
      if (no === '25') {
        const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
        this.headInfo.siteFlag = this.siteFlag
        this.$axios
          .post(BASEURL.update, this.headInfo)
          .then(resp => {
            this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
            this.handleDataSearch()
          })
          .finally(() => {
            loadingFlag.close()
          })
        return
      }

      this['gridOptions' + no].api.stopEditing()

      const tableData = this['tableData' + no]

      // 2-1-1 校验逻辑
      if (no === '211') {
        const sum1 = (tableData[0].quantity || 0) + (tableData[1].quantity || 0) +
        (tableData[2].quantity || 0) + (tableData[3].quantity || 0) + (tableData[4].quantity || 0)
        const sum2 = (tableData[5].quantity || 0) + (tableData[6].quantity || 0)
        if ((tableData[0].amount && sum1 === 0) || (tableData[5].amount && sum2 === 0)) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip1') })
        }
        if ((!tableData[0].amount && sum1 !== 0) || (!tableData[5].amount && sum2 !== 0)) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip1') })
        }
      }

      // 2-1-2 校验逻辑
      if (no === '212') {
        const sum1 = (tableData[0].quantity || 0) + (tableData[1].quantity || 0)
        const sum2 = (tableData[2].quantity || 0) + (tableData[3].quantity || 0) +
        (tableData[4].quantity || 0) + (tableData[5].quantity || 0) + (tableData[6].quantity || 0)
        if ((tableData[0].amount !== 0 && sum1 === 0) || (tableData[2].amount !== 0 && sum2 === 0)) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip1') })
        }
        if ((tableData[0].amount === 0 && sum1 !== 0) || (tableData[2].amount === 0 && sum2 !== 0)) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip1') })
        }
      }

      // 2-1-3 校验逻辑
      if (no === '213') {
        const sum = (tableData[0].quantity || 0) + (tableData[1].quantity || 0)
        // const isNotBalance1 = ((tableData[0].amount || 0) > 0 && sum === 0) ||
        // ((tableData[2].amount || 0) !== 0 && (tableData[2].quantity || 0) === 0)
        const isNotBalance2 = ((tableData[0].amount || 0) === 0 && sum !== 0) ||
        ((tableData[2].amount || 0) === 0 && (tableData[2].quantity || 0) !== 0)
        // if (isNotBalance1) {
        //   return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip1') })
        // }
        if (isNotBalance2) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip2') })
        }
      }

      // 2-2 校验逻辑
      if (no === '22') {
        const isNotBalance1 = ((tableData[0].amount || 0) !== 0 && (tableData[0].vehicles || 0) === 0) ||
        ((tableData[1].amount || 0) !== 0 && (tableData[1].vehicles || 0) === 0)
        const isNotBalance2 = ((tableData[0].amount || 0) === 0 && (tableData[0].vehicles || 0) !== 0) ||
        ((tableData[1].amount || 0) === 0 && (tableData[1].vehicles || 0) !== 0)
        if (isNotBalance1) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip3') })
        }
        if (isNotBalance2) {
          return this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip4') })
        }
      }

      // 2-6-1 ~ 2-6-6 校验逻辑
      if (no.indexOf('26') > -1) {
        let flag = true
        tableData.forEach(data => {
          if (flag) {
            if (data.accountsReceivableCompany) {
              if (!data.relation) {
                flag = false
                this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip9') })
              } else if (!data.amount) {
                flag = false
                this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip5') })
              } else if (data.amount < 0) {
                flag = false
                this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip8') })
              } else if (data.amount && !data.moneyUse) {
                flag = false
                this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip7') })
              }
            } else {
              if (data.amount || data.relation || data.moneyUse) {
                flag = false
                this.$message({ type: 'warning', message: this.$t('dealer_finance.operatingData.validateTip6') })
              }
            }
          }
        })
        if (!flag) return
      }

      // 2-7
      if (no === '27') {
        const a = this.$utils.accAdd(tableData[0].amount, tableData[1].amount)
        const b = this.$utils.accAdd(tableData[2].amount, a)
        tableData[3].amount = b
      }

      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })

      let url = BASEURL[`update${no[1]}`]

      this.$axios
        .post(url, tableData)
        .then(resp => {
          this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
          this.handleDataSearch()
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
<style lang="less" scoped>
  .page__wrapper {
    width: 100%;
    height: 100%;
    padding-right: 8px;
    .top-desc {
      font-size: 12px;
      padding: 4px 4px;
    }
    .table-title {
      padding: 20px 0px 8px 0;
      font-weight: 600;
    }
    .table-period {
      width: 100;
      text-align: right;
      font-size: 12px;
      padding: 32px 6px 8px 0;
    }
    .simple-table {
      font-size: 12px;
      .table-cell {
        border: 1px solid #c2c2c2;
        height: 30px;
        line-height: 28px;
      }
    }
  }
</style>
